import {
  Select as MuiSelect,
  MenuItem as MuiMenuItem,
  InputLabel,
  FormControl,
  OutlinedInput,
  Box,
  styled,
} from '@mui/material';
import { useState } from 'react';
import { Avatar, Chip } from 'components';
import { CaretDown, Check } from '@phosphor-icons/react';
import type { SelectProps as MuiSelectProps, SelectChangeEvent } from '@mui/material';
import { colors } from 'styles/theme/colors';

export type SelectProps = Pick<
  MuiSelectProps,
  'label' | 'multiple' | 'sx' | 'onOpen' | 'onClose' | 'open' | 'defaultOpen'
> & {
  id?: string;
  labelID?: string;
  onChange?: (e: SelectChangeEvent<string[]>) => void;
  variant?: 'standard' | 'filled' | 'outlined' | 'unstyled';
  options: Option[];
  initialValue?: string;
  actions?: JSX.Element;
  iconOnHover?: boolean;
  hasAvatar?: boolean;
};

export type Option = {
  label: string;
  avatar?: string;
  disableAvatar?: boolean;
  onClick?: (event: React.SyntheticEvent) => void;
};

// You have to provide MuiSelect with a component
const SelectIcon = () => {
  return (
    <IconContainer>
      <Icon />
    </IconContainer>
  );
};

export const Select = ({
  id,
  labelID,
  label,
  multiple,
  options,
  variant,
  onChange,
  iconOnHover = false,
  hasAvatar = false,
  actions,
  initialValue,
  sx,
  ...rest
}: SelectProps) => {
  const [value, setValue] = useState<string[]>(initialValue ? [initialValue] : []);

  const handleChange = (event: SelectChangeEvent<typeof value>) => {
    const { target } = event;
    setValue(typeof target.value === 'string' ? target.value.split(',') : target.value);
    onChange && onChange(event);
  };

  return (
    <Container
      sx={{
        ...(iconOnHover && {
          '.MuiSelect-select[aria-expanded="false"] ~ div': {
            display: 'none',
          },
          '.MuiSelect-select:hover ~ div': {
            display: 'initial',
          },
          '.MuiSelect-select[aria-expanded="true"] ~ div': {
            display: 'initial',
          },
        }),
      }}
    >
      <FormControl sx={{ m: 1, width: '100%' }}>
        {label && <InputLabel id={labelID}>{label}</InputLabel>}
        <MuiSelect
          {...rest}
          data-testId="select-component"
          disableUnderline={variant === 'unstyled'}
          labelId={labelID}
          sx={{
            minHeight: '48px',
            ...sx,
          }}
          id={id}
          multiple={multiple}
          IconComponent={SelectIcon}
          variant={variant === 'unstyled' ? 'standard' : variant}
          value={value}
          onChange={handleChange}
          input={label ? <OutlinedInput label={label} /> : undefined}
          renderValue={
            multiple
              ? renderValues => (
                  <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: '0.25rem' }}>
                    {renderValues.map(renderValue => (
                      <Chip key={renderValue} label={renderValue} />
                    ))}
                  </Box>
                )
              : selected => {
                  const currentOption = options.find(option => {
                    return selected.indexOf(option.label) !== -1;
                  });

                  return (
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: '0.25rem' }}>
                      {hasAvatar && !currentOption?.disableAvatar && (
                        <Avatar
                          size="xs"
                          sx={{ fontSize: '8px' }}
                          src={currentOption?.avatar}
                          letters={currentOption?.label}
                        />
                      )}
                      {selected}
                    </Box>
                  );
                }
          }
        >
          {options.map(option => (
            <MenuItem
              data-testId="menu-item"
              key={option.label}
              value={option.label}
              onClick={option.onClick}
              disableRipple
            >
              <MenuItemContent>
                <div
                  style={{
                    display: 'flex',
                    gap: '0.75rem',
                    alignItems: 'center',
                    paddingRight: '10px',
                  }}
                >
                  {hasAvatar && !option?.disableAvatar && (
                    <Avatar
                      size="xs"
                      sx={{ fontSize: '8px' }}
                      src={option.avatar}
                      letters={option.label}
                    />
                  )}
                  <span>{option.label}</span>
                </div>
                {value.indexOf(option.label) !== -1 && <Check color={colors.brand[400]} />}
              </MenuItemContent>
            </MenuItem>
          ))}
          {actions && <Actions>{actions}</Actions>}
        </MuiSelect>
      </FormControl>
    </Container>
  );
};

const Actions = styled('li')({
  padding: '0. 1rem',
  fontSize: '1rem',
});

const MenuItem = styled(MuiMenuItem)({
  '&.Mui-selected': {
    backgroundColor: 'inherit',
  },
  '&.Mui-selected:hover': {
    backgroundColor: colors.mintGreen[200],
  },
});

const MenuItemContent = styled('div')({
  display: 'flex',
  width: '100%',
  justifyContent: 'flex-start',
  alignItems: 'center',
});

const Container = styled('div')({
  // Same size as other inputs
  '.MuiSelect-standard': {
    borderRadius: '4px',
    minHeight: '1.4375em',
    paddingBottom: '16.5px',
    paddingTop: '16.5px',
    paddingLeft: '16.5px',
  },

  '.MuiSelect-standard:focus': {
    backgroundColor: 'unset',
  },
});

const IconContainer = styled(Box)`
  user-select: none;
  width: 1em;
  height: 1em;
  display: inline-block;
  fill: currentColor;
  flex-shrink: 0;
  transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  font-size: 1rem;
  position: absolute;
  right: 1em;
  top: calc(50% - 0.5em);
  pointer-events: none;
  color: rgba(0, 0, 0, 0.54);
`;

const Icon = styled(CaretDown)`
  pointer-events: none;
`;
